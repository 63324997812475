import "./lugares.css"
const Lugares = () =>{
return(
<>
<h1>Espacio y Tiempo</h1>
<article className="Lugares">
    <h2 className="h">Espacio</h2>
    <p className="b">En este capitulo podemos encontrar ciertos lugares:</p>
    <p className="d">Hay un lugar mencionado que es la casita de la bruja en el interior de Wisconsin no muy lejos de la colina.</p>
    <p className="e">Hay dos lugares que aparecen y donde suseden sucesos que estos son:</p>
    <p className="f">La colina en el estado de Wisconsin.</p>
    <p className="g">La carretera asfaltada en el estado de Wisconsin.</p>
</article>
<article>
    <h2>Tiempo</h2>
    <p>Esta historia susede en el pasado deducimos esto en que todos los verbos utilisados estan en pasado.</p>
</article>
<h2>Meme</h2>
    <picture>
        <img className="imgEspacio" src="../images/EspacioTiempo.jpeg" alt="MEME"/>
    </picture>
</>);
}
export default Lugares