import "./Footer.css"
const Footer = () =>{
    return(
<div className="Footer">
    <div className="DivFooter">
        <h4>Integrantes del grupo:</h4>
        <ul>
            <li>Milena Verdiani</li>
            <li>Julian Onofrio</li>
            <li>Tomás Fransoi</li>
        </ul>
    </div>

</div>
    )

}
export default Footer